import React from 'react'
import PipelineCard from '../PipelineCard'

export default function PipelinesList({
    pipelines,
    appInfo,
    user,
    setPipelines,
    customTab,
    setCustomTab,
    codeEditor,
    setCodeEditor
}) {
    return (
        <div className="flex">
            <div style={{ flex: 1 }}>
                {pipelines.length == 0 ?
                    <div className="flex justify-center mt5 mb5">
                        <bds-typo variant="fs-24" tag="h2" translate="" class="hydrated">Nenhum pipeline cadastrado</bds-typo>
                    </div> :
                    <div className="flex flex-column" style={{ gap: 10 }}>
                        {pipelines.map(pipeline =>
                            <PipelineCard
                                key={pipeline._id}
                                {...pipeline}
                                appInfo={appInfo}
                                user={user}
                                setPipelines={setPipelines}
                                customTab={customTab}
                                setCustomTab={setCustomTab}
                                codeEditor={codeEditor}
                                setCodeEditor={setCodeEditor}
                            />
                        )}
                    </div>
                }
            </div>
        </div>
    )
}