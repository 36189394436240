import React, { useState, useEffect } from 'react';
import Input from '../../Input/Input';
import moment from 'moment/moment';
import Button from '../../Button';
import { showToast, startLoading, stopLoading } from '../../../services/common-service';
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types';
import { generateAuthorizationToken } from '../../../services/utils';
import api from '../../../factory/api';
import Select from '../../Input/Select';

export default function InfoComponent({
    _id,
    name,
    description,
    status,
    created_at,
    appInfo,
    user,
    setPipeline
}) {
    const [inputName, setInputName] = useState("")
    const [inputDescription, setInputDescription] = useState("")
    const [inputStatus, setInputStatus] = useState()

    useEffect(() => {
        setInputName(() => name)
        setInputDescription(() => description)
        setInputStatus(() => status)
    }, [name, description, status])

    const save = async () => {
        try {
            startLoading()

            let { data } = await api.put(`/pipelines/${_id}`, {
                name: inputName,
                description: inputDescription,
                status: inputStatus
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setPipeline(() => data)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="ph3 pv3">
            <div>
                <Input
                    name="name"
                    label="Nome *"
                    placeholder="Qual nome gostaria de dar para este pipeline?"
                    value={inputName}
                    onChange={e => setInputName(e.target.value)}
                />
            </div>

            <div className="mt3">
                <Input
                    name="description"
                    label="Descrição"
                    placeholder="Gostaria de descrever este pipeline?"
                    value={inputDescription}
                    onChange={e => setInputDescription(e.target.value)}
                    isTextarea={true}
                    rows={10}
                />
            </div>

            <div className="mt3">
                <Select
                    label="Situação"
                    options={[
                        { text: "Ativado", value: 1 },
                        { text: "Desativado com retorno de sucesso", value: 2 },
                        { text: "Desativado com retorno de erro", value: 3 },
                    ]}
                    value={inputStatus}
                    onChange={e => setInputStatus(e.target.value)}
                />
            </div>

            {(inputName != name || inputDescription != description || inputStatus != status) &&
                <div className="mt2 flex justify-end">
                    <Button
                        text="Salvar"
                        onClick={save}
                    />
                </div>
            }

            <div className="mt3" style={{ textAlign: 'center' }}>
                <bds-typo variant="fs-14" tag="p" translate="" className="hydrated">Pipeline criado em {moment(created_at).format("DD/MM/YYYY - HH:mm:ss").replace("-", "às")}</bds-typo>
            </div>
        </div>
    )
}