import React, { useState } from 'react';

import moment from 'moment'
import { ObjectView } from 'react-object-view';
import ExecutionPipe from './ExecutionPipe';

export default function Execution({
    close,
    created_at,
    success,
    requisition,
    execution,
    codeEditor,
    setCodeEditor
}) {
    const [reqIsOpened, setReqIsOpened] = useState(false)

    return (
        <div className="pv3 ph3">
            <div class="flex items-center">
                <img className="bp-c-time pointer mr2" src="/arrowLeftIcon.svg" onClick={close} />

                <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-transclude="header"><header translate="">Execução</header></span>
            </div>

            <bds-paper className="relative paper__elevation--static" style={{ padding: 10, marginTop: 10 }}>
                <div className="flex items-center">
                    <div style={{ flex: 1 }}>
                        <bds-typo bold="bold" variant="fs-14" tag="h2" translate="" className="hydrated" style={{ color: success ? "green" : "red" }}>{success ? "Sucesso" : "Falhou"}</bds-typo>
                        <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated"><b>Início da execução:</b> {moment(created_at).format("DD/MM/YYYY - HH:mm:ss")}</bds-typo>
                    </div>
                </div>
            </bds-paper>

            <div className="mt4">

                <div className="mb2 flex items-center">
                    <img className="bp-c-time pointer mr2" src={`/arrow${reqIsOpened ? "Bottom" : "Right"}Icon.svg`} onClick={() => setReqIsOpened(opened => !opened)} />

                    <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-transclude="header"><header translate="">Requisição</header></span>
                </div>

                {reqIsOpened &&
                    <div className="object-view-base">
                        <ObjectView
                            data={requisition}
                        />
                    </div>
                }
            </div>

            <div className="mt4">
                <div className="mb2">
                    <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-transclude="header"><header translate="">Pipes</header></span>
                </div>

                <div>
                    {execution.map((pipe, index) =>
                        <ExecutionPipe
                            index={index}
                            pipe={pipe}
                            codeEditor={codeEditor}
                            setCodeEditor={setCodeEditor}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}