import React, { useState, useEffect } from 'react';
import Button from '../Button';
import { showToast, startLoading, stopLoading } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import api from '../../factory/api';
import ModalNewPipeline from '../ModalNewPipeline';
import PipelinesList from '../PipelinesList';
import { generateAuthorizationToken } from '../../services/utils';

export default function Pipelines({
    appInfo,
    user,
    customTab,
    setCustomTab,
    codeEditor,
    setCodeEditor
}) {
    const [isOpenedModalNew, setIsOpenedModalNew] = useState(false)
    const [pipelines, setPipelines] = useState([])

    useEffect(() => {
        loadPipelines()
    }, [])

    const loadPipelines = async () => {
        try {
            startLoading()

            let { data } = await api.get("/pipelines", {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setPipelines(() => data.data)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <div>
            <div className="flex mt3 mb3">
                <div className="flex" style={{ flex: 1 }}>
                    <bds-typo variant="fs-20" bold="semi-bold">
                        {pipelines.length > 0 ? `${pipelines.length} pipeline${pipelines.length != 1 ? "s" : ""}` : ""}
                    </bds-typo>
                </div>
                <div className="flex justify-end">
                    <Button
                        icon="refresh"
                        variant="secondary"
                        onClick={loadPipelines}
                        dataTestId="repository-link"
                    />

                    <Button
                        text="Criar pipeline"
                        onClick={() => setIsOpenedModalNew(() => true)}
                    />
                </div>
            </div>

            <PipelinesList
                pipelines={pipelines}
                appInfo={appInfo}
                user={user}
                setPipelines={setPipelines}
                customTab={customTab}
                setCustomTab={setCustomTab}
                codeEditor={codeEditor}
                setCodeEditor={setCodeEditor}
            />

            <ModalNewPipeline
                opened={isOpenedModalNew}
                setOpened={setIsOpenedModalNew}
                appInfo={appInfo}
                user={user}
                setPipelines={setPipelines}
            />
        </div>
    )
}