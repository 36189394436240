import React, { useRef, useState, useEffect } from 'react';
import Input from '../Input/Input';
import { showToast, startLoading, stopLoading } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import api from '../../factory/api';
import { generateAuthorizationToken } from '../../services/utils';

export default function ModalNewPipeline({
    opened,
    setOpened,
    appInfo,
    user,
    setPipelines
}) {
    const modalRef = useRef(null);

    const [inputName, setInputName] = useState("")
    const [inputDescription, setInputDescription] = useState("")

    useEffect(() => {
        const { current } = modalRef;
        current.addEventListener('bdsModalChanged', handleChange);

        return () => {
            current.removeEventListener('bdsModalChanged', handleChange);
        };
    }, [])

    useEffect(() => {
        setInputName("")
        setInputDescription("")
    }, [opened])

    const handleChange = () => {
        // alert("kk")
    }

    const close = () => {
        setOpened(() => false)
    }

    const submit = async () => {
        try {
            startLoading()

            if (inputName.trim() == "")
                throw { message: "O campo nome é obrigatório" }

            let { data } = await api.post("/pipelines", {
                name: inputName,
                description: inputDescription
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setPipelines(pipelines => [...pipelines, data])

            close()
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <bds-modal open={opened} close-button="false" ref={modalRef}>
            <div>
                <div>
                    <bds-typo variant="fs-20" bold="semi-bold">
                        Criar pipeline
                    </bds-typo>
                    <div>
                        <div>
                            <Input
                                name="name"
                                label="Nome *"
                                placeholder="Qual nome gostaria de dar para este pipeline?"
                                value={inputName}
                                onChange={e => setInputName(e.target.value)}
                            />
                        </div>

                        <div className="mt3">
                            <Input
                                name="description"
                                label="Descrição"
                                placeholder="Gostaria de descrever este pipeline?"
                                value={inputDescription}
                                onChange={e => setInputDescription(e.target.value)}
                                isTextarea={true}
                                rows={10}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <bds-modal-action>
                <div className="flex justify-end" style={{ width: "100%" }}>
                    <bds-button variant="secondary" onClick={close}>Cancelar</bds-button>
                    <bds-button onClick={submit}>Pronto</bds-button>
                </div>
            </bds-modal-action>
        </bds-modal>
    )
}