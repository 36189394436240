import React, { useEffect, useState } from 'react';
import { showToast, startLoading, stopLoading } from '../../../services/common-service';
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types';
import api from '../../../factory/api';
import { generateAuthorizationToken } from '../../../services/utils';
import moment from 'moment';
import Execution from './Execution';
import Button from '../../Button';

export default function ExecutionsComponent({
    _id,
    appInfo,
    user,
    setPipeline,
    codeEditor,
    setCodeEditor
}) {
    const [isLoading, setIsLoading] = useState(true)

    const [executions, setExecutions] = useState([])
    const [totalSize, setTotalSize] = useState(null)
    const [pageSize, setPageSize] = useState(10)

    const [page, setPage] = useState(1)

    const [executionOpened, setExecutionOpened] = useState(null)

    useEffect(() => {
        window.addEventListener('bdsPaginationChange', (event) => {
            setPage(() => event.detail)
        });
    }, [])

    useEffect(() => {
        getExecutions()
    }, [page])

    const getExecutions = async () => {
        try {
            setIsLoading(() => true)
            startLoading()

            let { data: result } = await api.get(`/executions/${_id}`, {
                params: {
                    page
                },
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            let { data, totalSize, pageSize } = result

            setExecutions(data)
            setTotalSize(totalSize)
            setPageSize(pageSize)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
            setIsLoading(() => false)
        }
    }

    if (executionOpened)
        return <Execution
            close={() => {
                setExecutionOpened(null)
                setCodeEditor(null)
            }}
            codeEditor={codeEditor}
            setCodeEditor={setCodeEditor}
            {...executionOpened}
        />

    return (
        <div>
            <div className="ph3 pv3">
                <div className="flex flex-column">
                    <span className="flex flex-row items-center justify-between mb3">
                        <span className="flex flex-row w-100 items-center">
                            <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-transclude="header"><header translate="">Execuções</header></span>
                        </span>
                        <div className="flex items-center justify-center" style={{ alignItems: "center" }}>
                            <span ng-className="$ctrl.extraClass" ng-transclude="extra" className="bp-fs-7 bp-c-city"><extra>{totalSize}</extra></span>

                            <Button
                                icon="refresh"
                                variant="secondary"
                                onClick={() => page == 1 ? getExecutions() : setPage(() => 1)}
                                dataTestId="repository-link"
                            />
                        </div>
                    </span>

                    <div className="flex flex-column" style={{ gap: 10 }}>
                        {executions.map((execution, index) =>
                            <bds-paper key={index} className="relative paper__elevation--static" style={{ padding: 10 }}>
                                <div className="flex items-center">
                                    <div style={{ flex: 1 }}>
                                        <bds-typo bold="bold" variant="fs-14" tag="h2" translate="" className="hydrated" style={{ color: execution.success ? "green" : "red" }}>{execution.success ? "Sucesso" : "Falhou"}</bds-typo>
                                        <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">{moment(execution.created_at).format("DD/MM/YYYY - HH:mm:ss")}</bds-typo>
                                    </div>
                                    <div className="items-center pointer" onClick={() => setExecutionOpened(execution)}>
                                        <img className="bp-c-time" src="/arrowRightIcon.svg" />
                                    </div>
                                </div>
                            </bds-paper>
                        )}

                        <div style={{ marginTop: 20, textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <bds-pagination
                                pages={pageSize ? Math.ceil(+(totalSize / pageSize)) : 1}
                                started-page={page}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}