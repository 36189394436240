import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import RoutesPath from '../../constants/routes-path';

import { showToast, startLoading, stopLoading } from '../../services/common-service'

import settings from '../../config';

import Header from './components/Header';
import api from '../../factory/api';
import { getApplicationDataAsync } from '../../services/application-service';
import { getLoggedUserAsync } from '../../services/user-service';
import CardInstance from '../../components/cards/CardInstance';
import { generateAuthorizationToken } from '../../services/utils';
import CardConnect from '../../components/cards/CardConnect';
import CardApresentation from '../../components/cards/CardApresentation';
import Copyright from '../../components/Copyright';
import CardToken from '../../components/CardToken';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import ErrorLoad from '../../components/ErrorLoad';
import CommandsList from '../../components/CommandsList';
import Start from '../../components/Start';
import Pipelines from '../../components/Pipelines';
import CustomTab from '../../CustomTab';
import CodeEditor from '../../components/CodeEditor';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const Home = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');

    const [isLoading, setIsLoading] = useState(true)

    const [instance, setInstance] = useState(null)

    const [error, setError] = useState(null)

    const [customTab, setCustomTab] = useState(null)
    const [codeEditor, setCodeEditor] = useState(null)

    const [connectionEdit, setConnectionEdit] = useState(false)

    const [validated, setValidated] = useState(false)

    // const { data } = useFetch(`/instances`);

    useEffect(() => {
        // if (appInfo != {} && user != {})
        if (appInfo?.tenantId && user?.culture)
            getInstance()
    }, [appInfo, user])

    useEffect(() => {
        getAppDataAsync()
    }, [])

    const handleNavigation = useCallback(
        (path, params = {}) => {
            history.push(path, params);
        },
        [history]
    );

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        console.log("appInfo", app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);
        console.log("loggedUser", loggedUser)

        setLanguage(i18n.language);
    };

    const getInstance = async () => {
        try {
            console.log("getInstance", {
                appInfo,
                user
            })
            startLoading()

            let { data } = await api.get("/instances", {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            if (!data.success)
                throw data

            setInstance(data.data)
        } catch (error) {
            if (error?.success == false)
                setError(() => error)

            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.data?.message || error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
            setValidated(true)
        }
    }

    if (!validated)
        return <></>

    return (
        <div className="flex" style={{ height: "100vh" }}>
            <div className="ph1 ph4-m ph5-ns pb5 relative" style={{ flex: 1 }}>
                <Header
                    title={t('title.homePage')}
                    icon={PAGE_ICON}
                    onClick={() => window.open("https://docs.sharkdev.com.br/blip/middlewareforblip", BLANK)}
                    instance={instance}
                    connectionEdit={connectionEdit}
                    setConnectionEdit={setConnectionEdit}
                />

                {error ?
                    <ErrorLoad {...error} /> :
                    <>
                        {(instance && !connectionEdit) &&
                            <>
                                <Pipelines
                                    appInfo={appInfo}
                                    user={user}
                                    pipelines={instance.pipelines}
                                    setInstance={setInstance}
                                    customTab={customTab}
                                    setCustomTab={setCustomTab}
                                    codeEditor={codeEditor}
                                    setCodeEditor={setCodeEditor}
                                />
                            </>
                        }

                        {(!instance || connectionEdit) &&
                            <Start
                                appInfo={appInfo}
                                user={user}
                                setInstance={setInstance}
                                connectionEdit={connectionEdit}
                                setConnectionEdit={setConnectionEdit}
                                instance={instance}
                            />
                        }
                    </>
                }

                <Copyright />

                {codeEditor &&
                    <CodeEditor
                        codeEditor={codeEditor}
                        setCodeEditor={setCodeEditor}
                    />
                }
            </div>

            <CustomTab
                opened={customTab != null}
                setCustomTab={setCustomTab}
                {...customTab}
            />
        </div>
    );
};

Home.propTypes = {};

export default Home;
