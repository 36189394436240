import React, { useState } from 'react';

import moment from 'moment';
import { ObjectView } from 'react-object-view';

export default function ExecutionPipe({
    pipe,
    codeEditor,
    setCodeEditor
}) {
    const [returnIsOpened, setReturnIsOpened] = useState(false)

    return (
        <bds-paper className="relative paper__elevation--static" style={{ padding: 10, marginTop: 10 }}>
            <div className="flex">
                <div style={{ flex: 1 }}>
                    <bds-typo bold="bold" variant="fs-16" tag="h2" translate="" className="hydrated">{pipe.pipe.name}</bds-typo>
                </div>
                <div className="flex">
                    <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated" bold="regular" style={{ marginRight: 5 }}>
                        {["blip_api", undefined].includes(pipe.pipe.action) ?
                            "API Blip" :
                            "Requisição HTTP"
                        }
                    </bds-typo>
                    {["blip_api", undefined].includes(pipe.pipe.action) && <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">/{pipe.pipe.path}</bds-typo>}
                </div>

                <img
                    src="/codeIcon.png"
                    style={{ width: 24, marginLeft: 10, cursor: "pointer" }}
                    onClick={() => {
                        setCodeEditor(() => ({
                            height: "100vh",
                            defaultLanguage: "javascript",
                            defaultValue: pipe.pipe.function,
                            theme: "vs-dark",
                            saveViewState: false,
                            loading: "Carregando código..."
                        }))
                    }}
                />
            </div>
            <bds-typo bold="bold" variant="fs-14" tag="h2" translate="" className="hydrated" style={{ color: pipe.success ? "green" : "red" }}>{pipe.success ? "Sucesso" : "Falhou"}</bds-typo>
            <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated"><b>Fim da execução: </b>{moment(pipe.execution_end_datetime).format("DD/MM/YYYY - HH:mm:ss")}</bds-typo>

            <hr />

            <div className="mb2 flex items-center">
                <img className="bp-c-time pointer mr2" src={`/arrow${returnIsOpened ? "Bottom" : "Right"}Icon.svg`} onClick={() => setReturnIsOpened(opened => !opened)} />

                <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-transclude="header"><header translate="">{pipe.success ? "Retorno da API" : "Retorno de erro"}</header></span>
            </div>

            {returnIsOpened &&
                <div className="object-view-base">
                    <ObjectView
                        data={pipe.error || pipe.response}
                    />
                </div>
            }
        </bds-paper>
    )
}