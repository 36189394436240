import React, { useEffect, useState } from 'react';

import "./styles.scss";
import Input from '../Input/Input';
import Button from '../Button';
import { generateAuthorizationToken } from '../../services/utils';
import { showToast, startLoading, stopLoading } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import api from '../../factory/api';

export default function Start({
    appInfo,
    user,
    setInstance,
    connectionEdit,
    setConnectionEdit,
    instance
}) {
    const [selected, setSelected] = useState(null)
    const [inputToken, setInputToken] = useState("")

    useEffect(() => {
        if (instance) {
            let token = generateAuthorizationToken(appInfo)

            select(instance.execution_token == token ? "this" : "other", instance.execution_token)
        }
    }, [instance])

    const select = (choice, token = "") => {
        if (choice == "this")
            setInputToken(token)
        setSelected(choice)
    }

    const submit = async () => {
        try {
            startLoading()

            if (!selected || (selected == "other" && inputToken.length != 60))
                return;

            let execution_token = selected == "this" ? generateAuthorizationToken(appInfo) : inputToken

            let { data } = await api[connectionEdit ? "put" : "post"]("/instances", {
                execution_token
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setInstance(data.data)
            if (connectionEdit)
                setConnectionEdit(false)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="w-100 m0 mb3 mb4-l mt4">
            <div id="create-application-marketplace-step" class="tc">
                <div class="tagline-title-container">
                    <bds-typo variant="fs-20" tag="h4" margin="false" translate="" class="hydrated">{connectionEdit ? "Precisa alterar? Vamos lá!" : "Vamos começar"}</bds-typo>
                    <bds-typo variant="fs-32" tag="h2" translate="" class="hydrated">Onde os comandos serão executados?</bds-typo>
                </div>
                <div className="w-50 center mt4">
                    <div class="marketplace-step-options flex justify-center flex-wrap">
                        <bds-paper class="option-card flex flex-column items-center pointer relative paper__elevation--static hydrated" selected={selected == "this"} onClick={() => select("this")}>
                            <bds-icon name="robot" size="brand" role="img" class="bds-icon bds-icon__size--brand hydrated" aria-label="integration" theme="outline"></bds-icon>
                            <bds-typo variant="fs-20" bold="bold" margin="false" line-height="plus" translate="" class="hydrated">Neste roteador</bds-typo>
                        </bds-paper>
                        <bds-paper class="option-card flex flex-column items-center pointer relative paper__elevation--static hydrated" selected={selected == "other"} onClick={() => select("other")}>
                            <bds-icon name="integration" size="brand" role="img" class="bds-icon bds-icon__size--brand hydrated" aria-label="integration" theme="outline"></bds-icon>
                            <bds-typo variant="fs-20" bold="bold" margin="false" line-height="plus" translate="" class="hydrated">Em outro roteador/bot</bds-typo>
                        </bds-paper>
                    </div>

                    {selected == "other" &&
                        <bds-paper class="relative paper__elevation--static hydrated mt4">
                            <Input
                                name="token"
                                label="Token"
                                placeholder="Informe o token do roteador do bot que será utilizado"
                                value={inputToken}
                                onChange={e => setInputToken(e.target.value)}
                            />
                        </bds-paper>
                    }

                    <div className="w-100 flex justify-center mt4">
                        <div className="mr3">
                            <Button
                                text="Cancelar"
                                onClick={() => setConnectionEdit(false)}
                            />
                        </div>
                        <Button
                            text="Avançar"
                            disabled={!selected || (selected == "other" && inputToken.length != 60)}
                            onClick={submit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}