import React, { useState, useEffect } from 'react';
import Select from '../../../Input/Select';
import InputEditable from '../../../InputEditable';

export default function PipeOpened({
    setNewPipes,
    setPipeOpened,
    _id,
    name,
    action = "blip_api",
    path,
    function: func,
    codeEditor,
    setCodeEditor,
    customTab
}) {
    const defaultCode = `/*
    Sempre utilize a função "run" para escrever seus scripts no Middleware for Blip.

    Entenda as props:
    - req: contém "body" e "header", trazidos pela requisição de origem.
    - response: contém o retorno do último consumo à API do Blip (o primeiro pipe do pipeline não trará response).
    - variables: objeto que é compartilhado entre todos os pipes. Se for alterado, o próximo pipe receberá esta variável com o novo valor.
    - stop: você pode utilizar o "return stop()" para paralizar o pipeline, sem gerar erro. Neste caso, você sairá do pipe atual imediatamente e mais nenhum pipe será executado.
    - next: você pode utilizar o "return next()" para sair imediatamente do pipe atual e ir para o próximo.
*/

function run({ req, response, variables, stop, next }) {

}`

    useEffect(() => {
        if (!func) {
            setNewPipes(newPipes => {
                for (let pipe of newPipes) {
                    if (pipe._id == _id) {
                        pipe.function = defaultCode
                        break
                    }
                }

                return [...newPipes]
            })
        }
    }, [_id])

    useEffect(() => {
        console.log(customTab)

        return () => setCodeEditor(null)
    }, [customTab])

    const handleChangedPipeName = ({ value }) => {
        // console.log("handleChangedPipeName", value)

        setNewPipes(newPipes => {
            for (let pipe of newPipes) {
                if (pipe._id == _id) {
                    pipe.name = value
                    break
                }
            }

            return [...newPipes]
        })
    }

    const handleChangedPipeAction = (event) => {
        let { detail: { value } } = event

        setNewPipes(newPipes => {
            for (let pipe of newPipes) {
                if (pipe._id == _id) {
                    pipe.action = value
                    if (value == "blip_api")
                        pipe.path = "commands"
                    else
                        pipe.path = null
                    break
                }
            }

            return [...newPipes]
        })
    }

    const handleChangedPipePath = (event) => {
        let { detail: { value } } = event

        setNewPipes(newPipes => {
            for (let pipe of newPipes) {
                if (pipe._id == _id) {
                    pipe.path = value
                    break
                }
            }

            return [...newPipes]
        })
    }

    const close = () => {
        setPipeOpened(null)
        setCodeEditor(null)
    }

    return (
        <div className="pv3 ph3">
            <div class="flex items-center">
                <img className="bp-c-time pointer mr2" src="/arrowLeftIcon.svg" onClick={close} />

                <InputEditable
                    value={name}
                    size="short"
                    onChange={handleChangedPipeName}
                />
            </div>

            <div className="mt2">
                <Select
                    label="Ação"
                    options={[
                        { text: "API Blip", value: "blip_api" },
                        { text: "Requisição HTTP", value: "http" },
                    ]}
                    value={action}
                    onChange={handleChangedPipeAction}
                    waitTime={50}
                />
            </div>

            {action == "blip_api" &&
                <div className="mt2">
                    <Select
                        label="Path a ser utilizado"
                        options={[
                            { text: "/commands", value: "commands" },
                            { text: "/messages", value: "messages" },
                            { text: "/notifications", value: "notifications" },
                        ]}
                        value={path}
                        onChange={handleChangedPipePath}
                    />
                </div>
            }

            <div className="flex pointer mt3">
                <div>
                    <img
                        src="/codeIcon.png"
                        style={{ maxWidth: 24 }}
                        className="mr2"
                    />
                </div>
                <div
                    class="flex items-center e2e-background"
                    onClick={() => {
                        setCodeEditor({
                            height: "100vh",
                            defaultLanguage: "javascript",
                            defaultValue: func || defaultCode,
                            theme: "vs-dark",
                            saveViewState: false,
                            loading: "Carregando código...",
                            onChange: (newCode) => {
                                // console.log(newCode);
                                setNewPipes(newPipes => {
                                    for (let pipe of newPipes) {
                                        if (pipe._id == _id) {
                                            pipe.function = newCode
                                            break
                                        }
                                    }

                                    return newPipes
                                })
                            },
                            onValidate: (test) => console.log(test)
                        })
                    }}
                >
                    <span class="bp-fs-5">Abrir script</span>
                </div>
            </div>
        </div >
    )
}