import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../components/PageHeader';
import Button from '../../../../components/Button';

const Header = ({
    title,
    icon,
    onClick,
    instance,
    connectionEdit,
    setConnectionEdit
}) => {
    const { t } = useTranslation();

    const renderOptions = () => (
        <div className="ml3 flex">
            {instance &&
                <Button
                    icon="edit"
                    variant="secondary"
                    onClick={() => setConnectionEdit(edit => !edit)}
                    dataTestId="repository-link"
                />
            }

            <Button
                text={t('button.document')}
                icon="link"
                variant="ghost"
                onClick={() => onClick()}
                dataTestId="repository-link"
            />
        </div>
    );

    return (
        <PageHeader
            title={title}
            icon={icon}
            relatedOptions={renderOptions()}
        />
    );
};

Header.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default Header;
