import React from 'react'

export default function Alert({
    opened = false,
    header,
    body,
    actions
}) {
    return (
        <bds-alert open={opened} className="hydrated">
            {header &&
                <bds-alert-header variant="system" icon="info" className="hydrated">{header}</bds-alert-header>
            }

            {body &&
                <bds-alert-body className="hydrated">{body}</bds-alert-body>
            }

            {(actions && actions.length > 0) &&
                <bds-alert-actions className="hydrated">
                    {actions.map((action, index) =>
                        <bds-button key={index} type-icon="icon" className="hydrated" {...action}>{action.title}</bds-button>
                    )}
                </bds-alert-actions>
            }
        </bds-alert>
    )
}