import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

export default function InputEditable({
    value,
    size,
    onChange
}) {
    const id = uuidv4()

    useEffect(() => {
        window.addEventListener('bdsInputEditableSave', e => {
            if (e?.target?.id == id)
                handleChange(e)
        });

        // return window.removeEventListener('bdsInputEditableSave', handleChange)
    }, [])

    const handleChange = (event) => {
        // console.log("handleChange", event)
        let { detail } = event

        if (onChange)
            onChange(detail)
    }

    return (
        <bds-input-editable
            id={id}
            value={value}
            size={size}
        />
    )
}