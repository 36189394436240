import React, { useEffect, useState, useRef } from 'react';

import "./styles.scss";
import Button from '../Button';
import Alert from '../Alert';
import { showToast, startLoading, stopLoading } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import api from '../../factory/api';
import { generateAuthorizationToken } from '../../services/utils';
import Input from '../Input/Input';
import InfoComponent from './InfoComponent';
import PipesComponent from './PipesComponent';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import VersionsComponent from './VersionsComponent';
import ExecutionsComponent from './ExecutionsComponent';

export default function PipelineCard({
    appInfo,
    user,
    _id,
    name,
    status,
    description,
    executions_quantity = 0,
    success_quantity = 0,
    error_quantity = 0,
    setPipelines,
    customTab,
    setCustomTab,
    created_at,
    pipes,
    versions,
    codeEditor,
    setCodeEditor
}) {
    const { t } = useTranslation();

    const [alertOpened, setAlertOpened] = useState(false)
    const [alert, setAlert] = useState({})
    const [newPipes, setNewPipes] = useState(null)

    const link = `https://middlewareforblip.blip.sharkdev.com.br/${_id}`

    useEffect(() => {
        if (alertOpened == false)
            setAlert({})
    }, [alertOpened])

    useEffect(() => {
        console.log("newPipes", newPipes)
        if (customTab && customTab?.id == _id)
            openCustomTab()
    }, [newPipes, name, description, status])

    useEffect(() => {
        if (!customTab || customTab?.id != _id)
            setNewPipes(undefined)
        else if (customTab && customTab?.id == _id && !newPipes)
            setNewPipes(pipes)
    }, [customTab])

    const setPipeline = (method) => {
        console.log("setPipeline", method, _id)
        setPipelines(pipelines => {
            for (let i = 0; i < pipelines.length; i++) {
                if (pipelines[i]._id == _id) {
                    console.log(method(pipelines[i]))
                    pipelines[i] = method(pipelines[i])
                    break
                }
            }

            return [...pipelines]
        })
    }

    const remove = async () => {
        setAlertOpened(() => true)
        setAlert({
            header: "Atenção!",
            body: "Tem certeza que deseja deletar este pipeline? Você não conseguirá recuperá-lo depois.",
            actions: [
                {
                    variant: "secondary",
                    bold: "true",
                    title: "Cancelar",
                    onClick: () => setAlertOpened(false)
                },
                {
                    variant: "primary",
                    bold: "true",
                    title: "Sim, tenho certo",
                    onClick: async () => {
                        try {
                            startLoading()

                            await api.delete(`/pipelines/${_id}`, {
                                headers: {
                                    Authorization: generateAuthorizationToken(appInfo),
                                    tenantId: appInfo.tenantId,
                                    language: user.culture
                                }
                            })

                            setPipelines(pipelines => pipelines.filter(pipeline => pipeline._id != _id))

                            setAlert(false)
                        } catch (error) {
                            showToast({
                                type: BlipPortalToastTypes.DANGER,
                                message: error?.message || "Ocorreu um erro"
                            });
                        } finally {
                            stopLoading()
                        }
                    }
                }
            ]
        })
    }

    const HeaderComponent = () =>
        <div className="pt2 flex" style={{ flexDirection: "space-between" }}>
            <div style={{ flex: 1 }}>
                <bds-typo variant="fs-20" tag="h4" translate="" className="hydrated">{name}</bds-typo>
            </div>

            <div>
                <img src="/close.svg" fill="red" onClick={() => setCustomTab(null)} className="pointer" />
            </div>
        </div>

    const openCustomTab = () => {
        // setNewPipes(pipes)
        setCustomTab(() => ({
            id: _id,
            reload: [newPipes],
            headerComponent: <HeaderComponent />,
            tabs: [
                {
                    id: "info",
                    label: "Informações",
                    component:
                        <InfoComponent
                            _id={_id}
                            name={name}
                            description={description}
                            status={status}
                            created_at={created_at}
                            appInfo={appInfo}
                            user={user}
                            setPipeline={setPipeline}
                        />
                },
                {
                    id: "pipes",
                    label: "Pipes",
                    component:
                        <PipesComponent
                            _id={_id}
                            name={name}
                            description={description}
                            created_at={created_at}
                            appInfo={appInfo}
                            user={user}
                            pipes={pipes}
                            setPipeline={setPipeline}
                            codeEditor={codeEditor}
                            setCodeEditor={setCodeEditor}
                            newPipes={newPipes}
                            setNewPipes={setNewPipes}
                            customTab={customTab}
                        />
                },
                {
                    id: "versoins",
                    label: "Versões",
                    component:
                        <VersionsComponent
                            name={name}
                            versions={versions}
                            pipes={pipes}
                            newPipes={newPipes}
                            setNewPipes={setNewPipes}
                        />
                },
                {
                    id: "executions",
                    label: "Execuções",
                    component:
                        <ExecutionsComponent
                            _id={_id}
                            appInfo={appInfo}
                            user={user}
                            setPipeline={setPipeline}
                            codeEditor={codeEditor}
                            setCodeEditor={setCodeEditor}
                        />
                }
            ]
        }))
    }

    const download = () => {
        // create file in browser
        const fileName = `pipes_${name.split(" ").join("_")}`;
        const json = JSON.stringify(pipes, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    return (
        <>
            <bds-paper className="relative paper__elevation--static hydrated">
                <div className="card">
                    <div className="flex">
                        <div className="" style={{ width: "100%" }}>
                            <div className="flex" style={{ width: "100%", flexDirection: "space-between", alignItems: "center" }}>
                                <div style={{ flex: 1, display: "flex" }}>
                                    <div style={{ width: 10, height: 10, backgroundColor: ["", "green", "gray", "red"][status], marginRight: 10, marginTop: 8, borderRadius: "100%" }}></div>
                                    <bds-typo variant="fs-24" tag="h2" translate="" className="hydrated">{name}</bds-typo>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingRight: 10, marginRight: 10, borderRight: "thin solid #d2dfe6" }}>
                                        <bds-typo variant="fs-22" tag="h2" translate="" className="hydrated">{executions_quantity}</bds-typo>
                                        <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">Execuções</bds-typo>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <bds-typo variant="fs-22" tag="h2" translate="" className="hydrated">{(success_quantity ? (success_quantity / executions_quantity) * 100 : 0).toFixed(0)}%</bds-typo>
                                        <bds-typo variant="fs-14" tag="h2" translate="" className="hydrated">De sucesso</bds-typo>
                                    </div>
                                </div>
                            </div>

                            <div className="details">
                                {description &&
                                    <div className="bp-bc-neutral-medium-wave bb mb2 pb2">
                                        <bds-typo variant="fs-14" tag="h3" translate="" className="hydrated">{description}</bds-typo>
                                    </div>
                                }

                                <div className="flex" style={{ alignItems: "flex-end" }}>
                                    <div style={{ flex: 1, justifyContent: "flex-end" }}>
                                        <bds-typo variant="fs-14" tag="h3" translate="" className="hydrated">Link para webhook:</bds-typo>

                                        <CopyToClipboard
                                            text={link}
                                            onCopy={() =>
                                                showToast({
                                                    type: BlipPortalToastTypes.SUCCESS,
                                                    title: t('messages.copy_to_clipboard'),
                                                    message: t('messages.copy_to_clipboard')
                                                })
                                            }
                                        >
                                            <div style={{ borderRadius: 15, display: 'flex', backgroundColor: "#DBF1F3", padding: "10px 20px", cursor: "pointer" }}>
                                                <input style={{ flex: 1, margin: 0, color: "#15afb2", color: "#15afb2", backgroundColor: "transparent", border: 0, cursor: "pointer" }} disabled={true} value={link} />

                                                <div>

                                                </div>
                                            </div>
                                        </CopyToClipboard>
                                    </div>

                                    <div className="buttons-area">
                                        <div className="flex">
                                            <Button
                                                icon="download"
                                                variant="secondary"
                                                onClick={download}
                                                dataTestId="download-link"
                                            />

                                            <Button
                                                icon="edit"
                                                variant="secondary"
                                                onClick={openCustomTab}
                                                dataTestId="open-link"
                                            />

                                            <Button
                                                icon="trash"
                                                variant="secondary"
                                                onClick={remove}
                                                dataTestId="repository-link"
                                            />
                                            {/* <Button
        icon="download"
        variant="secondary"
        onClick={remove}
        dataTestId="repository-link"
    /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </bds-paper>

            <Alert
                opened={alertOpened}
                setAlert={setAlert}
                {...alert}
            />
        </>
    )
}