import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

const Select = ({
    name,
    label,
    placeholder,
    icon,
    value,
    options = [],
    danger = false,
    focused = false,
    waitTime = 500,
    errors = {},
    touched = {},
    onFocus,
    onBlur,
    onChange,
    disabled = false
}) => {
    const blipInputRef = useRef(null);
    const [error, setError] = useState('');
    let time = null;

    useEffect(() => {
        const { current } = blipInputRef;
        current.addEventListener('optionSelected', handleChange);
        // current.addEventListener('bdsChange', handleChange);
        current.addEventListener('bdsFocus', onFocus);
        current.addEventListener('bdsOnBlur', onBlur);

        return () => {
            current.removeEventListener('optionSelected', handleChange);
            // current.removeEventListener('bdsChange', handleChange);
            current.removeEventListener('bdsFocus', onFocus);
            current.removeEventListener('bdsOnBlur', onBlur);
        };
    }, []);

    // useEffect(() => {
    //     const { current } = blipInputRef;
    //     if (!!focused && !!current) {
    //         current.setFocus();
    //     }
    // }, [focused]);

    // useEffect(() => {
    //     setError(
    //         getIn(touched, name) && getIn(errors, name)
    //             ? getIn(errors, name)
    //             : ''
    //     );
    // }, [touched, errors, name]);

    const handleChange = (e) => {
        console.log("handleChange", e)
        clearTimeout(time);
        time = setTimeout(() => onChange(e), waitTime);
    };

    return (
        <div className="relative">
            <bds-select
                // data-testid="bds-input"
                ref={blipInputRef}
                input-name={name}
                label={label}
                placeholder={placeholder}
                icon={icon}
                value={value}
                danger={!!error || danger}
                disabled={disabled}
                // options={options}
            >
                {options.map(({
                    text,
                    bulkOption,
                    slotAlign,
                    ...rest
                }, index) =>
                    <bds-select-option
                        key={index}
                        title-text={text}
                        bulk-option={bulkOption}
                        slot-align={slotAlign}
                        {...rest}
                    />
                )}
            </bds-select>
        </div>
    );
};

Select.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.string,
    danger: PropTypes.bool,
    focused: PropTypes.bool,
    waitTime: PropTypes.number,
    errors: PropTypes.object,
    touched: PropTypes.object,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.array,
};

export default Select;
