import React, { useState, useEffect, useRef } from 'react';
import Input from '../../Input/Input';
import moment from 'moment/moment';
import Button from '../../Button';
import { showToast, startLoading, stopLoading } from '../../../services/common-service';
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types';
import { generateAuthorizationToken } from '../../../services/utils';
import api from '../../../factory/api';
import { v4 as uuidv4 } from 'uuid';


import "./styles.scss"
import PipeOpened from './PipeOpened';

export default function PipesComponent({
    _id,
    name,
    description,
    created_at,
    appInfo,
    user,
    setPipeline,
    pipes,
    codeEditor,
    setCodeEditor,
    newPipes = [],
    setNewPipes,
    customTab
}) {
    const inputUploadRed = useRef()
    const dragItem = useRef();
    const dragOverItem = useRef();

    const [pipeOpened, setPipeOpened] = useState(null)

    useEffect(() => {
        //     setNewPipes(pipes)
        //     setPipeOpened(null)
        // alert(pipes.length)
    }, [pipes])

    const savePipes = async (pipes) => {
        try {
            console.log("savePipes", pipes)
            startLoading()

            let { data } = await api.put(`/pipelines/${_id}`, {
                pipes
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setPipeline(() => data)
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    const removePipe = (index) => {
        setNewPipes(newPipes => {
            newPipes.splice(index, 1)
            return [...newPipes];
        })
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
    };

    const drop = (e) => {
        const copyListItems = [...newPipes];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setNewPipes(copyListItems);
    };


    if (pipeOpened)
        return <PipeOpened
            {...pipeOpened}
            setNewPipes={setNewPipes}
            setPipeOpened={setPipeOpened}
            codeEditor={codeEditor}
            setCodeEditor={setCodeEditor}
            customTab={customTab}
        />

    return (
        <div className="ph3 pv3">
            <div className="flex flex-column">
                <span className="flex flex-row items-center justify-between">
                    <span className="flex flex-row w-100 items-center">
                        <span className="lh-solid bp-lh-simple bp-fs-5 bp-c-city ttu b" ng-className="$ctrl.headerClass" ng-transclude="header"><header translate="">Pipes</header></span>
                        {/* <i ng-click="$ctrl.toggleInfo($event)" className="ml2 icon icon-info bp-c-silver"></i> */}
                    </span>
                    <span ng-className="$ctrl.extraClass" ng-transclude="extra" className="bp-fs-7 bp-c-city"><extra>{newPipes.length}</extra></span>
                </span>
                <span className="mt3"><info translate="">Cadastre os pipes <strong>na ordem correta</strong> de execução</info></span>

                <div className="no-style list">
                    {newPipes.map((pipe, index) =>
                        <div
                            key={index}
                            className="anchor-container action-icons-container"
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={drop}
                            draggable
                        >
                            <div className="flex justify-center">
                                <div className="pointer pl2 mv4 br4 w-90 bp-fs-6 flex justify-center">
                                    <div className="anchor mr2">
                                        <img src="/dragAndDropIcon.png" />
                                    </div>

                                    <span className="w-10 mr3 bp-lh-simple">
                                        <img src="/jsicon.svg" alt="" />
                                    </span>

                                    <div className="w-80 pointer" onClick={() => setPipeOpened(pipe)}>
                                        <span>{pipe.name}</span>
                                    </div>
                                    <span className="w-15 action-icons flex justify-center">
                                        {/* <div className="copy-blip-button">
                                        <bds-icon size="x-small" name="copy" className="mr-2 bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated" role="img" aria-label="copy" theme="outline"></bds-icon>
                                    </div> */}
                                        <bds-icon
                                            size="x-small"
                                            name="trash"
                                            className="bp-lh-simple bp-c-cloud bp-fs-5 bds-icon bds-icon__size--x-small hydrated"
                                            role="img"
                                            aria-label="trash"
                                            theme="outline"
                                            onClick={() => removePipe(index)}
                                        ></bds-icon>
                                    </span>
                                </div>
                                <div className="flex justify-center mh3 arrowContainer items-center pointer" onClick={() => setPipeOpened(pipe)}>
                                    <img className="bp-c-time" src="/arrowRightIcon.svg" />
                                </div>
                            </div>
                            <div className="bp-divider-h bp-divider w-100"></div>
                        </div>
                    )}
                </div>

                <div className="pt3">
                    <div
                        className="add-action-button bp-btn bp-btn--bot bp-btn--dashed flex justify-center items-center h3-1 w-100 b"
                        onClick={() => {
                            setNewPipes(pipes => [...pipes, {
                                _id: uuidv4(),
                                name: `Pipe ${pipes.length + 1}`,
                                action: 'blip_api',
                                path: 'commands'
                            }])
                        }}
                    >+ Adicionar pipe</div>

                    <div className="pt3 flex">
                        <Button
                            variant="primary"
                            text="Salvar pipes"
                            onClick={() => savePipes(newPipes)}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}